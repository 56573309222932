<template>
  <div class="component-holder">
    <div class="menu-row">
      <div class="buttons-holder">
        <div
          class="buttons"
          :class="`menu-buttons-showing-${isShowingMenuButtons}`"
        >
          <button class="action" title="Copy" @click="generateExport">
            <font-awesome-icon class="hamburger" icon="copy" />
          </button>

          <!-- <button class="action" title="Paste" @click="loadFromClipboard">
            <font-awesome-icon class="hamburger" icon="paste" />
          </button> -->
          <button class="action" title="Clear" @click="clear">
            <font-awesome-icon class="hamburger" icon="eraser" />
          </button>
          <button
            class="action"
            title="How to Use"
            @click="isShowingHowToUseDialog = true"
          >
            <font-awesome-icon class="hamburger" icon="circle-question" />
          </button>
          <!-- <button class="action" title="About the App" @click="showInfo">
            <font-awesome-icon class="hamburger" icon="circle-info" />
          </button> -->
        </div>
        <button class="btn-menu" @click.stop="isShowingMenuButtons = true">
          <font-awesome-icon class="hamburger" icon="bars" />
        </button>
      </div>
    </div>

    <div class="entered-cells">
      <div
        class="entered-cell"
        v-for="(t, i) in $store.state.scratchPad.frozenText"
        :key="i"
      >
        <div
          :contenteditable="t.status !== 'frozen'"
          @focus="editText(t, i)"
          :class="t.status"
        >
          {{ t.text }}
        </div>
      </div>
    </div>
    <div class="new-row">
      <div
        ref="editBox"
        class="edit-box"
        contenteditable="true"
        @keypress="updateEditText"
        @keyup.enter="freezeText"
        @focus="onFocus"
        @blur.stop="showMenuButtons"
      >
        <div class="instructions" v-if="isShowingInstructionsText">
          Input text to get started. Hit <strong>Enter/Return</strong> to save
          line.
          <span class="instructions-edit">Click within 5 seconds to edit.</span>
          After 5 seconds, text is permanently frozen.
        </div>
      </div>
    </div>
    <div class="export-text" v-if="!!exportedText">
      <h4>The following has been copied to the clipboard:</h4>
      <div class="export-text" v-html="exportedText"></div>
    </div>
    <!-- <pre>{{ pastedText }}</pre> -->
  </div>

  <div
    class="dlg-show-how-to-use-holder"
    :class="`dlg-show-how-to-use-holder-showing-${isShowingHowToUseDialog}`"
  >
    <div
      class="dlg-show-how-to-use-silkscreen"
      @click.prevent="isShowingHowToUseDialog = false"
    >
      <div class="dlg-show-how-to-use-dialog" @click.stop>
        <div class="dlg-show-how-to-use-xmark-holder">
          <font-awesome-icon
            class="dlg-show-how-to-use-xmark"
            icon="xmark"
            @click.stop="isShowingHowToUseDialog = false"
          ></font-awesome-icon>
        </div>
        <div class="dlg-show-how-to-use-content-holder">
          <div class="dlg-show-how-to-use-content">
            <h2>How to Use</h2>
            <p>
              The goal of FreezePad is to get ideas out of your head and onto
              the screen where you can copy everything to your favorite editor.
            </p>

            <p>
              Input text into the focus box to get started. Hit
              <strong>Enter/Return</strong> to save line.
              <span class="instructions-edit"
                >Saved text can be edited for 5 seconds by clicking on it while
                blue.</span
              >
              After 5 seconds, text background will turn white, indicating it is
              frozen.
            </p>

            <p>
              After capturing your thoughts, use the hamburger menu to copy so
              you can paste everything into your favorite editing tool (e.g Roam
              Research to restructure an outline and then Microsoft Word for
              editing).
            </p>
            <ul>
              <li>
                <font-awesome-icon class="howto-icon" icon="copy" />
                <strong>Copy</strong> Saved Text to Clipboard
              </li>
              <li>
                <font-awesome-icon class="howto-icon" icon="eraser" />
                <strong>Clear</strong>
                Saved Text
              </li>
            </ul>

            <h2>Regarding Your Data</h2>
            Data is stored locally on your browser and is never sent to any
            server. This means no one can see what you write but you.

            <h2>Author's Note</h2>

            <p>
              I always thought of myself as a terrible, talentless writer. My
              ADHD tendency to prematurely edit my thoughts meant that I had
              little to share because all my insights were being filtered out.
              Hours of work left me with nothing but an empty screen and naked
              cursor.
            </p>

            <p><i>Have you been there?</i></p>

            <p>
              Several professional writers advised separating writing from
              editing, and “all it takes is practice”. After several years of
              false starts, stuck in the proverbial mud of constant
              word-slinging perfectionism, I realized I needed a tool to tie my
              hands. And since I could not find it, I created FreezePad.
            </p>

            <p>
              FreezePad is a minimalist capture tool whose only purpose is to
              get thoughts out of your head and onto the screen. Like a
              typewriter or pen and paper (but without the messy Wite-Out!),
              this tool lets you scribble down thoughts without allowing editing
              to become a distraction. Because fat finger mistakes and bad
              autocorrect happen to even the best of us, FreezePad allows 5
              seconds to make quick changes before freezing things down to keep
              your thoughts flowing.
            </p>
            <p>
              Thanks for giving FreezePad a try. Feel free to reach out with any
              questions and comments.
            </p>
            <p style="text-align: right">
              <i>Cedric Yau</i>
              <br />
              Tweet to @ctyau
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang='scss' scoped>
h2 {
  margin-top: 1em;
}
.dlg-show-how-to-use-holder {
  font-family: Verdana, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &.dlg-show-how-to-use-holder-showing-false {
    .dlg-show-how-to-use-silkscreen {
      height: 0;
      width: 0;
      overflow: hidden;
    }
  }

  &.dlg-show-how-to-use-holder-showing-true {
    .dlg-show-how-to-use-silkscreen .dlg-show-how-to-use-dialog {
      opacity: 1;
      transform: unset;
    }
  }

  .dlg-show-how-to-use-xmark-holder {
    font-size: 150%;
    text-align: right;
    .dlg-show-how-to-use-xmark {
      opacity: 0.6;
      cursor: pointer;
      &:hover {
        opacity: 1;
      }
    }
  }

  .dlg-show-how-to-use-silkscreen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: #8888;
    z-index: 1;
    backdrop-filter: blur(2px);

    .dlg-show-how-to-use-dialog {
      position: relative;
      top: 10vh;
      left: 10vw;
      width: 80vw;
      height: 80vh;
      background-color: white;
      border: 2px solid black;
      display: flex;
      flex-direction: column;
      padding: 1ex 1em;
      border-radius: 1em;
      box-shadow: 0 0.25em 1em #555;

      opacity: 0;
      transform: scale(0.5);
      transition: opacity 0.25s, transform 0.25s;

      .dlg-show-how-to-use-content-holder {
        height: 100%;
        overflow-y: scroll;
      }
    }
  }
}

.menu-row {
  margin-bottom: 0.25em;
  height: 2em;
}
.show-menu {
  color: #ccc;
  text-align: right;
  width: 100%;
}
button.btn-menu {
  background-color: rgba(0, 0, 0, 0);
  border: 0px;
  color: #ccc;
  // height: 2em;
}
.hamburger {
  height: 1.5em;
}
.howto-icon {
  height: 1.5em;
  margin-right: 0.5em;
}
.component-holder {
  font-family: Verdana, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.instructions {
  font-style: italic;
  text-align: center;
  opacity: 0.6;
  width: 100%;
  /* height: 100%; */
  // position: absolute;
  // top: 3em;
  /* left: 1em; */
  z-index: -1;
}
.instructions-edit {
  background-color: hsla(188, 100%, 86%, 1);
}
.editable {
  opacity: 0.8;
}

.almostfrozen {
  opacity: 0.5;

  /* font-weight: bold; */
  border-bottom: 1px dashed gray;
  background-color: hsla(188, 100%, 86%, 1);
  padding: 0.5ex 0.5em;
}
.frozen {
  opacity: 1;
  /* font-weight: bold; */
  border-bottom: 1px dashed gray;
  transition: all 1s ease;
  padding: 0.5ex 0.5em;
}
.edit-box {
  border: 1px solid gray;
  padding: 0.5ex 0.5em;
  /* font-weight: bold; */
  margin: -1px;
}

.export-text {
  background: rgb(239, 239, 239);
  margin-top: 1em;
}

.buttons-holder {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.buttons {
  display: flex;
  flex-direction: row;
  overflow: hidden;
  transition: width 0.5s;
  width: 8.5em;
}
button.action {
  cursor: pointer;
  margin: 0.25em;
  padding: 0.25em 0.75em;
  font-size: 80%;
  border-radius: 1em;
  border: 0;
  color: #333;
  opacity: 0.7;
  &:hover {
    opacity: 1;
  }
}

.menu-buttons-showing-false {
  width: 0em;
}

@keyframes reheight-on-appear {
  0% {
    height: 0;
  }
  99% {
    height: 1.5em;
  }
  100% {
    height: unset;
  }
}
.entered-cell {
  height: 0;
  overflow: hidden;
  animation: reheight-on-appear 0.5s forwards;
}

.howto-focus-box {
  border: 2px solid black;
}

// @media (max-width: 75em) {
//   .edit-box {
//     // margin-top: 2em;
//     min-height: 2em;
//   }
// }
</style>

<script>
export default {
  name: "App",
  components: {},
  data() {
    return {
      exportedText: "",
      isEdit: false,
      isShowingHowToUseDialog: false,

      pastedText: "",
      currentEditText: "",
      isShowingInstructionsText: true,
      isShowingMenuButtons: true,
    };
  },
  methods: {
    onFocus(e) {
      console.log("focus:", e);
    },
    showMenuButtons(e) {
      console.log("blur", e);
      this.isShowingMenuButtons = true;
    },
    freezeText(ev) {
      // if (ev.shiftKey) {
      //   return;
      // }
      console.log(ev);
      const newText = ev.target.innerText.trim().replace("\n", "<br />");
      console.log(newText);
      this.$store.commit("scratchPad/addNewFrozenText", newText);
      ev.target.innerHTML = "";
    },
    focusOnInput() {
      console.log("mounted focus");
      this.$refs.editBox.focus();
    },
    generateExport() {
      const allText = this.$store.state.scratchPad.frozenText.map(
        (e) => e.text
      );
      const clipboardText = allText.join("\n\n").replace("<br />", "\n");
      const clipboardHTML = allText
        .join("<br /><br />")
        .replace("\n", "<br />");
      console.log("clipboardText:", clipboardText);
      console.log("clipboardHTML:", clipboardHTML);
      const clipboardBlobPlain = new Blob([clipboardText], {
        type: "text/plain",
      });
      const clipboardBlobHTML = new Blob([clipboardHTML], {
        type: "text/html",
      });
      const clipboardItemInput = new window.ClipboardItem({
        "text/html": clipboardBlobHTML,
        "text/plain": clipboardBlobPlain,
      });

      navigator.clipboard.write([clipboardItemInput]);

      this.exportedText = clipboardHTML;
    },
    async loadFromClipboard() {
      this.pastedText = await navigator.clipboard.readText();
      const splitText = this.pastedText.split("\n").filter((s) => s.length > 0);
      splitText.forEach((e) =>
        this.$store.commit("scratchPad/addNewFrozenText", e)
      );
    },
    updateEditText() {
      this.isShowingInstructionsText = false;
      this.isShowingMenuButtons = false;
    },
    editText(e, index) {
      this.$refs.editBox.innerText = e.text.replace("<br />", "\n");
      this.$refs.editBox.focus();

      this.$store.commit("scratchPad/popText", index);
      console.log("editText:", e, index);
    },
    clear() {
      this.$store.commit("scratchPad/clearFrozenText");
      this.exportedText = "";
      this.currentEditText = "";
    },
  },
  computed: {},
  mounted() {
    console.log("store", this.$store);
    console.log("store.state.scratchPad", this.$store.state.scratchPad);
    console.log("mounted scratch");
    this.$nextTick(() => {
      this.focusOnInput();
      this.$store.commit("scratchPad/freezeExisting");
    });
    this.$store.dispatch("scratchPad/init");

    this.isShowingInstructionsText =
      this.$store.state.scratchPad &&
      this.$store.state.scratchPad.frozenText?.length === 0;
  },
};
</script>




