const moduleScratchPad = {
  namespaced: true,

  state() {
    return {
      frozenText: [],
      changeTimer: null,
    };
  },
  mutations: {
    addNewFrozenText(state, newText) {
      const status = "almostfrozen";
      const newIndex =
        state.frozenText.push({
          status,
          text: newText,
        }) - 1;
      console.log("adding newIndex:", newIndex);

      if (state.changeTimer) {
        window.clearInterval(state.changeTimer);
      }
      state.changeTimer = setTimeout(() => {
        for (const i of state.frozenText) {
          if (i.status === "almostfrozen") {
            i.status = "frozen";
          }
        }
        state.changeTimer = null;
      }, 5000);

      window.localStorage.setItem(
        "frozenTextJSON",
        JSON.stringify(state.frozenText)
      );
      return newIndex;
    },
    freezeText(state, index) {
      console.log("FreezeText:", index);
    },
    freezeExisting(state) {
      for (const i of state.frozenText) {
        i.status = "frozen";
      }
    },
    popText(state, indexToRemove) {
      console.log("Popping:", indexToRemove);
      state.frozenText.splice(indexToRemove, 1);
      window.localStorage.setItem(
        "frozenTextJSON",
        JSON.stringify(state.frozenText)
      );
    },
    clearFrozenText(state) {
      state.frozenText = [];
      window.localStorage.setItem(
        "frozenTextJSON",
        JSON.stringify(state.frozenText)
      );
    },
    replaceFrozenText(state, newFrozenText) {
      state.frozenText = newFrozenText;
    },
  },
  actions: {
    init(context) {
      const storedDataStr = window.localStorage.getItem("frozenTextJSON");
      if (!storedDataStr) {
        return;
      }
      const storedData = JSON.parse(storedDataStr);
      if (!storedData) {
        return;
      }
      context.commit("replaceFrozenText", storedData);
    },
  },
  getters: {},
};

export default moduleScratchPad;
