import { createStore } from "vuex";

import moduleScratchPad from "./modules/scratchpad";
export default createStore({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    scratchPad: moduleScratchPad,
  },
});
