import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import store from "./store";

// region font awesome
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faBars,
  faCircleQuestion,
  faCircleInfo,
  faCopy,
  faPaste,
  faEraser,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
library.add([
  faBars,
  faCircleInfo,
  faCircleQuestion,
  faCopy,
  faPaste,
  faEraser,
  faXmark,
]);

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

// endregion font awesome
createApp(App)
  .use(store)
  .component("font-awesome-icon", FontAwesomeIcon)
  .mount("#app");
